@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "bulma/css/bulma.css";

html {
  background: linear-gradient(black 60%, white) no-repeat;
  color: white;
}

.pointer {
  cursor: pointer;
}

.header {
  width: 100%;
}

.bgwhite {
  background-color: white;
}

.withShadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.68);
}

.headerText {
  text-align: center;
  white-space: nowrap;
  position: relative;
}

.amountBox {
  text-align: center;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.68);
  padding: 1vw;
}

.iconContainer {
  padding: 10px 0 0 0;
}

.contestLine {
  padding: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.68);
}

.contestLine .red {
  display: inline-block;
  width: 50%;
  height: 100%;
  background: #bf211e;
}

.contestLine .black {
  display: inline-block;
  width: 50%;
  height: 100%;
  background: black;
}

.headerContainer {
  margin-top: 4vw;
  text-align: center;
}

.aboutLink {
  font-family: Futura;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 3vw;
  color: black;
}

.disclaimer {
  font-family: Futura;
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
  line-height: 4vw;
  letter-spacing: 0.325em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin-top: 3vw;
}

.mainContainer {
  margin-top: 6vw;
  margin-bottom: 20px;
}

.contestantPhoto {
  width: 30vw;
  /* height: 7vh; */
  object-fit: contain;
}

.contestantContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -15px;
}

.shareItemMargin {
  margin-bottom: 25px;
}

.shareLabel {
  color: rgb(128, 128, 128);
  font-size: 1vw;
  font-weight: 800;
  white-space: nowrap;
}

.rotated1 {
  transform: rotate(270deg);
}

.rotated2 {
  transform: rotate(90deg);
}

.icon {
  width: 20px;
}

.iconClipboard {
  display: block;
  width: 2vw;
  height: 1.5vw;
}

.iconEth {
  width: 3vw;
  height: 2.4vw;
}

.pointer {
  cursor: pointer;
}

.footerContainer {
  margin-top: 6vw;
}

.meterLeft {
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: white;
  border: 1px solid #fff;
  height: 6vw;
  width: 30vw;
  color: #000;
  padding-right: 2rem;
}

.meterRight {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #000;
  border: 1px solid #fff;
  height: 6vw;
  width: 30vw;
  padding-left: 2rem;
}

.meterCount {
  color: black;
  font-family: Futura;
  font-style: normal;
  font-weight: 900;
  font-size: 3vw;
  line-height: 3vw;
  text-transform: uppercase;
}

/* .meterIcon {
  margin-right: 0.5rem;
} */

.ethContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ethLabel {
  /* margin-right: 0.5rem; */
  font-size: 2vh;
  color: rgb(128, 128, 128);
  font-weight: bold;
  margin-bottom: 2vh;
}

.ethWallet {
  font-family: Futura;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1vw;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #e5e5e5;
}

.sideText {
  font-weight: 800;
  font-size: 1vw;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.crossIcon {
  position: absolute;
  right: -50px;
  top: -50px;
  font-size: 1vw;
  cursor: pointer;
}

.ethLink {
  color: black;
  font-weight: 600;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
}

pre {
  font-size: 1vw;
}

.margin {
  margin-top: 3rem;
}

.boldText {
  font-weight: 600;
}

.width100 {
  width: 100%;
}

.voteText {
  font-size: 1vw;
  text-align: center;
  margin-top: 13vw;

  font-family: Futura;
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
  line-height: 0px;
  color: white;
  /* identical to box height, or 0% */

  letter-spacing: 0.105em;
  text-transform: uppercase;
}
