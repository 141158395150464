.modalContent {
  padding: 60px;
  color: black;
}

.modalTitle {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 3vw;
  font-weight: 900;
  margin-bottom: 30px;
}

.modalText {
  font-size: 1vw;
}

.black {
  color: black;
}
